import React from "react"
import styled from "styled-components"
import { getGridSpans } from '@stylesheets/settings'
import { injectIntl, Link } from "gatsby-plugin-intl"

import Layout from '../components/layout'
import SEO from '../components/seo'

const Wrapper = styled.div`
  ${getGridSpans('width', { s:4, m: [4, 'full'], l: 6 })};
  margin: 0 auto;
  padding: 50px 0;
`;

const StyledLink = styled(Link)`
  display: block;
  margin-top: 20px;
  text-decoration: underline;
`;


const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Wrapper>
      <h1>Nicht gefunden</h1>
      <p>Upps... diese Seite gibt es leider nicht.</p>
      <StyledLink to="/">Zurück</StyledLink>
    </Wrapper>
  </Layout>
)

export default NotFoundPage
